import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import moment from 'moment'

import {
	ElButton,
	ElInput,
	ElIcon,
	ElMessage,
	ElMessageBox,
	ElContainer,
	ElHeader,
	ElAside,
	ElMain,
	ElMenu,
	ElSubMenu,
	ElMenuItem,
	ElScrollbar,
	ElTable,
	ElTableColumn,
	ElLoading,
	ElDialog,
	ElCascader,
	ElSelect,
	ElOption,
	ElTag,
	ElInputNumber,
	ElTree,
	ElPagination,
	ElUpload,
	ElSwitch,
	ElLink,
	ElRadioGroup,
	ElRadio,
	ElDatePicker,
	ElRow
} from 'element-plus'
import 'element-plus/dist/index.css'
import * as AllIcon from '@element-plus/icons-vue'

const app = createApp(App)
app.config.globalProperties.$moment = moment
Object.keys(AllIcon).forEach((key) => {//循环遍历组件名称
    if ("Menu" !== key) {//如果不是图标组件不是Menu，就跳过，否则加上ICon的后缀
        app.component(key, AllIcon[key]);
    } else {
        app.component(key + "Icon", AllIcon[key]);
    }
})

app.use(ElButton)
app.use(ElInput)
app.use(ElIcon)
app.use(ElMessage)
app.use(ElMessageBox)
app.use(ElContainer)
app.use(ElHeader)
app.use(ElAside)
app.use(ElMain)
app.use(ElMenu)
app.use(ElSubMenu)
app.use(ElMenuItem)
app.use(ElScrollbar)
app.use(ElTable)
app.use(ElTableColumn)
app.use(ElLoading)
app.use(ElDialog)
app.use(ElCascader)
app.use(ElSelect)
app.use(ElOption)
app.use(ElTag)
app.use(ElInputNumber)
app.use(ElTree)
app.use(ElPagination)
app.use(ElUpload)
app.use(ElSwitch)
app.use(ElLink)
app.use(ElRadioGroup)
app.use(ElRadio)
app.use(ElDatePicker)
app.use(ElRow)
app.use(store).use(router).mount('#app')
