import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		token: sessionStorage.getItem('token'),
		scheme: sessionStorage.getItem('scheme'),
		defaultid: sessionStorage.getItem('defaultid'),
		defaultname: sessionStorage.getItem('defaultname')
	},
	mutations: {
		setLoginContent(state, data) {
			state.token = data.token
			state.scheme = data.scheme
			sessionStorage.setItem('token', data.token)
			sessionStorage.setItem('scheme', data.scheme)
		},
		setdefaultid(state, id) {
			state.defaultid = id
			sessionStorage.setItem('defaultid', id)
		},
		setdefaultname(state, name) {
			state.defaultname = name
			sessionStorage.setItem('defaultname', name)
		}
	},
	actions: {},
	modules: {}
})
