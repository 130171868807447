<template>
	<el-config-provider :locale="locale">
		<div id="app">
			<router-view></router-view>
		</div>
	</el-config-provider>
</template>
<script>
	import {
		ElConfigProvider
	} from 'element-plus'
	import zhCn from 'element-plus/lib/locale/lang/zh-cn'
	export default {
		name: 'App',
		components: {
			[ElConfigProvider.name]: ElConfigProvider,
		},
		data() {
			return {
				locale: zhCn,
			}
		}
	}
</script>
<style>
	* {
		margin: 0;
		padding: 0;
	}
	.contentbox p {
		font-size: 16px;
		color: #666666;
		line-height: 36px;
		margin-bottom: 10px;
		text-transform: uppercase;
	}
	.el-scrollbar {
		width: 100%;
		background: #FFFFFF;
	}

	.el-breadcrumb {
		width: 100%;
		background: #FFFFFF;
		padding: 10px;
		border-bottom: 1px solid #eeeeee;
	}

	.content {
		padding: 10px;
	}

	.viewtitle {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 10px;
	}

	.viewtitle .el-icon {
		cursor: pointer;
	}

	.viewtitle label {
		padding-left: 10px;
	}

	.btnview {
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.el-button .el-icon {
		margin-right: 5px;
	}

	.el-menu-item.is-active {
		color: #0088FF !important;
	}

	.fromitem {
		margin-bottom: 10px;
	}

	.fromname {
		padding-bottom: 5px;
	}

	.fyq {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 30px;
	}
</style>
