import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: function () {
      return import('../views/Login.vue')
    },
		meta: {
			changeToken: false
		}
  },
	{
	  path: '/home',
	  name: 'home',
	  component: function () {
	    return import('../views/Home.vue')
	  },
		meta: {
			changeToken: true
		},
		children: [
			{
				path: '/home',
				name: 'count',
				component: function () {
				  return import('../components/count/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/menu',
				name: 'menu',
				component: function () {
				  return import('../components/menu/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/roles',
				name: 'roles',
				component: function () {
				  return import('../components/roles/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/message',
				name: 'message',
				component: function () {
					return import('../components/message/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/rolesadd',
				name: 'rolesadd',
				component: function () {
				  return import('../components/roles/add.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/rolesedit',
				name: 'rolesedit',
				component: function () {
				  return import('../components/roles/edit.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/roleseditinfo',
				name: 'roleseditinfo',
				component: function () {
				  return import('../components/roles/editinfo.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/user',
				name: 'user',
				component: function () {
				  return import('../components/user/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/site',
				name: 'site',
				component: function () {
				  return import('../components/site/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/categories',
				name: 'categories',
				component: function () {
				  return import('../components/categories/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/groups',
				name: 'groups',
				component: function () {
				  return import('../components/groups/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/article',
				name: 'article',
				component: function () {
				  return import('../components/article/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/articlexaminelist',
				name: 'articlexaminelist',
				component: function () {
				  return import('../components/article/examinelist.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/labelist',
				name: 'labelist',
				component: function () {
				  return import('../components/label/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/articleadd',
				name: 'articleadd',
				component: function () {
				  return import('../components/article/add.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/articleedit',
				name: 'articleedit',
				component: function () {
				  return import('../components/article/edit.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/recycle',
				name: 'recycle',
				component: function () {
				  return import('../components/recycle/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/examine',
				name: 'examine',
				component: function () {
				  return import('../components/examine/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/examinelist',
				name: 'examinelist',
				component: function () {
				  return import('../components/examine/elist.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/links',
				name: 'links',
				component: function () {
				  return import('../components/links/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/webinfo',
				name: 'webinfo',
				component: function () {
				  return import('../components/webinfo/info.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/banner',
				name: 'banner',
				component: function () {
				  return import('../components/banner/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/opinion',
				name: 'opinion',
				component: function () {
				  return import('../components/opinion/list.vue')
				},
				meta: {
					changeToken: true
				}
			}
		]
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
	if (to.matched[0].meta.changeToken) {
		let token = sessionStorage.getItem('token')
		if (token === null || token === '') {
			next('/')
		} else {
			next()
		}
	} else {
		next()
	}
})
export default router
